import React, { useState } from 'react';
import { useService } from 'rc-service';
import { useMediaQuery, useSidebar } from '@agroop/app-shell';
import { Input, useForm } from '@agroop/forms';
import { Link, RouteFnProps } from '@agroop/router';
import { Tab, TabBar } from '@agroop/ui/lib/TabBar';
import { Grid, GridCell } from '@agroop/ui/lib/Grid';
import { I18nService } from '@agroop/common/services';
import { Button, ButtonBar } from '@agroop/ui/lib/Button';
import { parseQueryMemo } from '@agroop/common/utils/url';
import { mdcThemeTextSecondaryOnBackground, typographyBodyMedium } from '@agroop/ui/lib/mdc';
import { IcApple, IcArrowForward, IcFacebook, IcGoogle, IcVisibility, IcVisibilityOff } from '@agroop/icons';
import BgVideo from '@agroop/app-shell/lib/components/BgVideo';

import { LoginFD } from 'app/FirebaseTypes';
import BaseFooter from 'app/components/BaseFooter';
import { continueWithApple, continueWithEmailAndPassword, continueWithFacebook, continueWithGoogle } from 'app/Firebase';

import brandImg from '../images/brand.svg';
import styles from './login.module.scss';

const LoginPage: React.FC<RouteFnProps> = () => {
  const [isSmall] = useMediaQuery(matchMedia(`(max-width: 400px)`));

  const { t } = useService(I18nService);
  const sidebar = useSidebar({ scrim: 'invisible', animated: false, style: { background: '#FFF' } });

  const { email } = parseQueryMemo<{ email?: string }>(location.search);

  const form = useForm<LoginFD>({
    formData: { mode: 'in', email },
    onSubmit: continueWithEmailAndPassword,
  });

  const [showPassword, setShowPassword] = useState(true);
  const IconActive = showPassword ? IcVisibilityOff : IcVisibility;

  const mode = form.formData.mode;
  const signInMode = mode === 'in';
  return (
    <>
      <BgVideo />
      {sidebar.render(
        <form.Provider>
          <div className={styles.outerContent}>
            <LoginBrand />
            <TabBar className={styles.tabBar}>
              <Tab label={t('login.signIn')} active={signInMode} onClick={() => form.setValue('mode', 'in')} />
              {import.meta.env.VITE_AUTH_SIGNUP_AVAILABLE && (
                <Tab label={t('login.signUp')} active={mode === 'up'} onClick={() => form.setValue('mode', 'up')} />
              )}
            </TabBar>
          </div>
          <Grid className={styles.grid}>
            <GridCell span={12}>
              <Input name="email" type="email" required autoComplete="email" label={t('main.email')} validations={['email']} />
            </GridCell>
            <GridCell span={12}>
              <Input
                name="password"
                type={showPassword ? 'password' : 'text'}
                required
                autoComplete="current-password"
                label={t('main.password')}
                validations={['password']}
                trailingIcon={<IconActive tabIndex={0} role="button" onClick={() => setShowPassword(!showPassword)} />}
              />
            </GridCell>
            {signInMode ? (
              <GridCell span={12} wrap>
                <Link className={`${styles.link} ${mdcThemeTextSecondaryOnBackground}`} to="../forgotPassword">
                  {t('login.forgotPassword')}
                </Link>
              </GridCell>
            ) : (
              <GridCell span={12} wrap>
                <div className={styles.bump} />
              </GridCell>
            )}
            <GridCell span={12} wrap>
              <Button
                raised
                type="submit"
                disabled={form.submitting}
                trailingIcon={<IcArrowForward />}
                text={`${signInMode ? t('login.login') : t('login.getStarted')}`}
              />
            </GridCell>
            {import.meta.env.VITE_AUTH_SIGNUP_AVAILABLE && (
              <GridCell span={12} wrap>
                <div className={`${mdcThemeTextSecondaryOnBackground} ${typographyBodyMedium}`} style={{ marginTop: '24px' }}>
                  {t('login.useSocial')}
                </div>
                <ButtonBar align="start">
                  <Button
                    className={styles.socialButton}
                    style={{ marginBottom: '16px' }}
                    raised
                    lower
                    text="Google"
                    icon={!isSmall ? <IcGoogle /> : undefined}
                    onClick={continueWithGoogle}
                  />
                  <Button
                    className={styles.socialButton}
                    style={{ marginBottom: '16px' }}
                    raised
                    lower
                    text="Facebook"
                    icon={!isSmall ? <IcFacebook /> : undefined}
                    onClick={continueWithFacebook}
                  />
                  <Button
                    className={styles.socialButton}
                    style={{ marginBottom: '16px' }}
                    raised
                    lower
                    text="Apple"
                    icon={!isSmall ? <IcApple /> : undefined}
                    onClick={continueWithApple}
                  />
                </ButtonBar>
              </GridCell>
            )}
          </Grid>
          <BaseFooter backgroundColor="#fff" />
        </form.Provider>,
      )}
    </>
  );
};

function LoginBrand() {
  return (
    <a href="http://agroop.net" target="_blank" rel="noopener noreferrer">
      <img src={brandImg} alt="Farmoot" />
    </a>
  );
}

export default LoginPage;
