import React from 'react';

import { useService } from 'rc-service';

import { I18nService } from '@agroop/common/services';
import BgVideo from '@agroop/app-shell/lib/components/BgVideo';
import { Redirect } from '@agroop/router';
import { SidebarHeader, useSidebar } from '@agroop/app-shell';
import { IcArrowBack, IcArrowForward } from '@agroop/icons';
import { List, SimpleListItem } from '@agroop/ui/lib/ItemList';
import { assignRipple } from '@agroop/ui/lib/utils/ripple';
import { appIcons } from '@agroop/common/utils/app';

import { authService } from 'app/services';
import { goToApp } from 'app/Firebase';
import BaseFooter from 'app/components/BaseFooter';

import styles from './appSelect.module.scss';

function AppSelectPage() {
  const { t } = useService(I18nService);
  const sidebar = useSidebar({ scrim: 'invisible', animated: false, onClose: authService.logout });
  if (!authService.isLoggedIn) return <Redirect to="/login" replace />;

  return (
    <>
      <BgVideo />
      {sidebar.render(
        <>
          <SidebarHeader closeIcon={<IcArrowBack />} title={t('appSelect.title')} />
          <List twoLine padded avatarList>
            {authService.user.apps
              .slice()
              .reverse()
              .map(app => (
                <SimpleListItem
                  key={app}
                  innerRef={assignRipple}
                  className={styles.app}
                  text={t(`appSelect.apps.${app}.name`)}
                  secondaryText={t(`appSelect.apps.${app}.description`)}
                  graphic={appIcons[app]}
                  meta={<IcArrowForward />}
                  onClick={() => goToApp(app)}
                />
              ))}
          </List>
          <BaseFooter />
        </>,
      )}
    </>
  );
}

export default AppSelectPage;
