import React from 'react';

import { useServiceInstance } from 'rc-service';

import { Redirect } from '@agroop/router';
import { SidebarHeader, useSidebar } from '@agroop/app-shell';
import { Input, Select, useForm } from '@agroop/forms';
import { themeBg, typographyBodyLarge, typographyBodyMedium, typographyTitleMedium } from '@agroop/ui/lib/mdc';
import { Grid, GridCell } from '@agroop/ui/lib/Grid';
import { Button } from '@agroop/ui/lib/Button';
import { IcInfo } from '@agroop/icons';
import { createDigitalFarmerTrial } from '@agroop/api/farmoo/entities';
import { DigitalFarmerRequest } from '@agroop/api/farmoo/types';
import { farmooUrl } from '@agroop/common/utils/app';
import { unsupportedPlanetCountries } from '@agroop/common/constants/entity';
import BgVideo from '@agroop/app-shell/lib/components/BgVideo';

import { authService, fixedValuesService, i18nService } from 'app/services';
import { goToAppUrl } from 'app/Firebase';
import { InfoTip } from 'app/components/InfoTip';
import BaseFooter from 'app/components/BaseFooter';

import styles from './CreateEntity.module.scss';

const loadCountries = () => fixedValuesService.getAllAsync('countries');

export function CreateEntity() {
  if (!authService.isLoggedIn) return <Redirect to="/login" replace />;

  useServiceInstance(fixedValuesService);
  const { t } = i18nService;
  const sidebar = useSidebar({ scrim: 'invisible', animated: false, style: { background: '#FFF' } });

  const form = useForm<DigitalFarmerRequest>({
    onSubmit: body => createDigitalFarmerTrial({ body }),
    onAfterSubmit: () => goToAppUrl(farmooUrl),
  });

  return (
    <>
      <BgVideo />
      {sidebar.render(
        <form.Provider>
          <SidebarHeader title={t('createEntity.title')} />
          <Grid className={`${typographyBodyMedium} ${styles.welcome} p-16 ${themeBg}`}>
            <GridCell span={12} wrap>
              <div>{t('createEntity.socialWelcome.welcome')}</div>
              <div>{t('createEntity.socialWelcome.aboutYourCompany')}</div>
            </GridCell>
            <GridCell>
              <div className={`${typographyBodyLarge} ${styles.step}`}>{t('createEntity.step2')}</div>
            </GridCell>
          </Grid>
          <Grid className={styles.grid}>
            <GridCell span={12} wrap>
              <Input name="entityName" label={t('createEntity.companyName')} required />
            </GridCell>
            <GridCell tablet={4} phone={2}>
              <Select
                name="countryCode"
                required
                label={t('createEntity.country')}
                labelKey="name"
                valueKey="code"
                searchable
                simpleValue
                loadOptions={loadCountries}
                defaultValue={authService.user.countryCode}
              />
            </GridCell>
            <GridCell tablet={8} phone={4}>
              <Input name="totalArea" type="number" label={t('createEntity.totalHa')} min={0} required />
            </GridCell>
            {unsupportedPlanetCountries.includes(form.formData.countryCode) && (
              <GridCell span={12} wrap>
                <div className={styles.warningHeader}>
                  <span className={typographyTitleMedium}>{t('createEntity.warning')}</span>
                  <InfoTip message={`${t('createEntity.unsupportedCountries')}: ${unsupportedPlanetCountries.join(', ')}`} />
                </div>
                <p>{t('createEntity.unsupportedCountriesMessage')}</p>
              </GridCell>
            )}
            <GridCell span={12} wrap>
              <div className={styles.footer}>
                <IcInfo />
                <div>{t('createEntity.trialMessage')}</div>
              </div>
            </GridCell>
            <GridCell span={12} wrap>
              <Button raised type="submit" disabled={!form.dirty || form.submitting} text={t('createEntity.drawYourFields')} />
            </GridCell>
          </Grid>
          <BaseFooter backgroundColor="#fff" />
        </form.Provider>,
      )}
    </>
  );
}
