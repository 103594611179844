import React from 'react';

import { useService } from 'rc-service';

import { RouteFnProps } from '@agroop/router';
import { SidebarHeader, useSidebar, UiService } from '@agroop/app-shell';
import { Button } from '@agroop/ui/lib/Button';
import { Grid, GridCell } from '@agroop/ui/lib/Grid';
import { Input, useForm } from '@agroop/forms';
import { IcArrowForward, IcArrowBack } from '@agroop/icons';
import { I18nService } from '@agroop/common/services';
import { resetPassword } from '@agroop/api/accounts/authentication';
import { linkToOrigin } from '@agroop/common/utils/url';
import BgVideo from '@agroop/app-shell/lib/components/BgVideo';

import BaseFooter from 'app/components/BaseFooter';
import { goToLogin } from 'app/utils/auth';
import { uiService } from 'app/services';

interface ForgotPasswordFD {
  email: string;
}

const ForgotPasswordPage: React.FC<RouteFnProps> = () => {
  const { t, tr } = useService(I18nService);
  const ui = useService(UiService);
  const sidebar = useSidebar({ scrim: 'invisible', animated: false, onClose: goToLogin });
  const form = useForm<ForgotPasswordFD>({
    onSubmit: async fd => {
      try {
        await resetPassword({ email: fd.email, redirect: linkToOrigin('/login') })
          .then(() => ui.showAlert(tr('popups.forgotPassword')))
          .then(goToLogin);
      } catch (error: any) {
        if (error.data.code === 422) throw await uiService.showAlert(tr('popups.nonExistingEmail'));
      }
    },
  });
  return (
    <>
      <BgVideo />
      {sidebar.render(
        <form.Provider>
          <SidebarHeader closeIcon={<IcArrowBack />} title={t('forgotPassword.title')} />
          <Grid>
            <GridCell span={12}>
              <Input name="email" type="email" required label={t('main.email')} validations={['email']} />
            </GridCell>
            <GridCell span={12} wrap>
              <Button raised type="submit" disabled={form.submitting} trailingIcon={<IcArrowForward />} text={t('forgotPassword.send')} />
            </GridCell>
          </Grid>
          <BaseFooter />
        </form.Provider>,
      )}
    </>
  );
};

export default ForgotPasswordPage;
